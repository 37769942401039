import { useStaticQuery, graphql } from 'gatsby';

const useArenaInfoMenPage = () => {
    const { sanityPageArenaInfoMen: PageArenaInfoMen } = useStaticQuery(graphql`
        query {
            sanityPageArenaInfoMen {
                title {
                    en
                    se
                } 
                _rawContent
            }
        }
    `);

    return PageArenaInfoMen;
};

export default useArenaInfoMenPage;
