import React, { useContext } from 'react';

import { LangContext } from 'context/LangContext';
import * as style from 'styles/components/subPartners/list.module.scss';
import useArenaInfoMenPage from 'data/queries/useArenaInfoMenPage';
import ArenaMenu from 'components/arenainfo/arenaMenu';
// import { getFormattedMessage } from 'components/utils/FormattedMessage';
import TextEditorRender from 'components/utils/TextEditorRender';

const ArenaInfoMen = () => {
    const { lang } = useContext(LangContext);
    const data = useArenaInfoMenPage();
    return (
        <div>
            <div className={`${style.partnerList__content} wrapper-s`}>
                <ArenaMenu
                    activeArena="arenaInfoMen"
                />
                {data.title && (
                    <h1 className="title-sm-mobile title-lg text-semibold">{data.title[lang.hreflang]}</h1>
                )}
                <main>
                    {data._rawContent ? (
                        <div className={style.partnerList__description}>
                            <TextEditorRender content={data._rawContent} />
                        </div>
                    ): null}
                </main>
            </div>
        </div>
    );
};

export default ArenaInfoMen;
